<template>
    <div class="about">
        <!-- banner -->
        <div class="banner">
            <img src="@/assets/imgs/about/1.png" alt="" v-if="!mobile" />
            <img src="@/assets/imgs/about/1_1.png" alt="" v-else />
        </div>

        <!-- warp-one -->
        <div class="warp-one">
            <div class="content">
                <div class="title">關於iHK</div>
                <ul class="df">
                    <li class="df-col" v-for="(item, index) in ihkData.about_ihk.content.list"  :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                        <div class="sub_tip">{{ item.content }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-two -->
        <div class="warp-two" id="form">
            <div class="content df-sb">
                <div class="left">
                    <div class="title">歡迎商業洽談</div>
                    <div class="tip">{{ initData.collaborate && initData.collaborate.content }}</div>
                    <div class="title">加入我們</div>
                    <div class="tip">{{ initData.join_us_content && initData.join_us_content.content }}</div>
                    <div class="sub_title">電話</div>
                    <div class="tip">{{ initData.contact_number && initData.contact_number.content }}</div>
                    <div class="sub_title">電郵</div>
                    <div class="tip">{{ initData.email && initData.email.content }}</div>
                </div>
                <div class="right">
                    <div class="title">聯絡表單</div>
                    <el-form class="form" ref="form" :model="form" :rules="rules">
                        <div class="cooperation_view df-sb-cen">
                            <el-form-item class="cooperation_item width100p" prop="name">
                                <div class="ms-flex">
                                    <el-select v-model="nameCode" slot="prepend" class="width100">
                                        <el-option :label="$t('m.mr')" value="1"></el-option>
                                        <el-option :label="$t('m.mi')" value="2"></el-option>
                                    </el-select>
                                    <el-input class="ms-flex-1" v-model="form.name" :placeholder="$t('m.name')"></el-input>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="cooperation_view df-sb-cen">
                            <el-form-item class="cooperation_item" prop="mobile">
                                <div class="ms-flex">
                                    <el-select v-model="phoneCode" slot="prepend" class="width150">
                                        <el-option :label="$t('m.hong_kong')" value="852"></el-option>
                                        <el-option :label="'+86（' + '中國大陸' + '）'" value="86"></el-option>
                                        <!-- <el-option :label="'+886（' + '中國台灣' + '）'" value="886"></el-option> -->
                                        <el-option :label="'+853（' + '中國澳門' + '）'" value="853"></el-option>
                                    </el-select>
                                    <el-input class="ms-flex-1" :placeholder="$t('m.tel_number')" v-model="form.mobile"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item class="cooperation_item" prop="email">
                                <el-input v-model="form.email" :placeholder="$t('m.mailbox')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="cooperation_view df-sb-cen">
                            <el-form-item style="width: 100%" prop="company">
                                <el-input v-model="form.company" :placeholder="$t('m.company_name')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="cooperation_view df-sb-cen">
                            <el-form-item style="width: 100%" prop="notes">
                                <el-input type="textarea" v-model="form.notes" :placeholder="$t('m.ly')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="cooperation_view_submit">
                            <el-button class="cooperation_view_submit_btn" @click="submitForm('form')">{{ $t('m.submit') }}</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>

        <el-dialog class="form-dialog" :visible.sync="successVisible" width="430px" top="30vh" :close-on-click-modal="true" :append-to-body="true" :before-close="handleClose">
            <img class="image" src="@/assets/imgs/about/icon_success@2x.png" alt="" />
            <p class="text1">
                {{ $t('m.submitted_successfully') }}
            </p>
            <p class="text2">
                {{ $t('m.thank_you_for_your_attention') }}
            </p>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            initData: 'app/initData',
            ihkData: 'app/ihkData',
        }),
    },
    data() {
        return {
            form: {
                name: '',
                company: '',
                mobile: '',
                email: '',
                notes: '',
                source: 5,
                platform: 'pc',
            },
            nameCode: '1',
            phoneCode: '852',
            rules: {
                name: [{ validator: this.userNameValidator, trigger: 'blur' }],
                company: [{ validator: this.companyNameValidator, trigger: 'blur' }],
                mobile: [{ validator: this.phoneValidator, trigger: 'blur' }],
                email: [{ validator: this.emailValidator, trigger: 'blur' }],
                notes: [{ required: false }],
            },
            successVisible: false,
            mobile: false,
        };
    },
    methods: {
        phoneValidator(rule, value, callback) {
            const reg1 = /^1[3-8][0-9]{9}$/;
            const reg2 = /^[965][0-9]{7}$/;
            const reg3 = /^6[68][0-9]{5}$/;
            const reg4 = /^09[0-9]{8}$/;
            if (value.trim() === '') {
                callback('请输入電話號碼');
            } else {
                if (this.phoneCode == 86) {
                    if (!reg1.test(value)) callback(this.$t('m.incorrect_phone_format'));
                    else {
                        this.isPhone = true;
                        callback();
                    }
                } else if (this.phoneCode == 852) {
                    if (!reg2.test(value)) callback(this.$t('m.incorrect_phone_format'));
                    else {
                        this.isPhone = true;
                        callback();
                    }
                } else if (this.phoneCode == 853) {
                    if (!reg3.test(value)) callback(this.$t('m.incorrect_phone_format'));
                    else {
                        this.isPhone = true;
                        console.log(this.isPhone);
                        callback();
                    }
                } else if (this.phoneCode == 886) {
                    if (!reg4.test(value)) callback(this.$t('m.incorrect_phone_format'));
                    else {
                        this.isPhone = true;
                        callback();
                    }
                }
            }
        },
        emailValidator(rule, value, callback) {
            const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            if (value.trim() === '') {
                callback(this.$t('m.please_input_your_email'));
            } else {
                if (!reg.test(value)) callback(this.$t('m.email_format_incorrect'));
                else {
                    this.isEmail = true;
                    callback();
                }
            }
        },
        userNameValidator(rule, value, callback) {
            if (value.trim() === '') {
                callback(this.$t('m.please_type_in_your_name'));
            } else {
                this.isName = true;
                callback();
            }
        },
        companyNameValidator(rule, value, callback) {
            if (value.trim() === '') {
                callback(this.$t('m.please_enter_company_name'));
            } else {
                this.isCompany = true;
                callback();
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.form.serverCode = 1;
                    this.form.area_code = this.phoneCode;
                    this.form.name_code = this.nameCode;
                    this.form.h5platform = this.getOS();
                    this.$api.saveContactus(this.form).then((res) => {
                        if (res.code == 200) {
                            this.successVisible = true;
                        } else {
                            this.$message.error({
                                message: res.message,
                                offset: 100,
                            });
                        }
                    });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClose() {
            this.successVisible = false;
            this.$refs.form.resetFields();
        },
    },
    created() {
        this.mobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768;
    },
    mounted() {
        const domId = this.$route.params && this.$route.params.domId;
        if (domId) {
            setTimeout(() => {
                document.getElementById(domId).scrollIntoView({ block: 'center', behavior: 'smooth' });
            }, 500);
        }
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-input input:focus,
select:focus {
    border: 1px solid #404040;
    outline: none;
    color: #404040;
}
@media (min-width: 768px) {
    .about {
        .banner {
            img {
                width: 100%;
            }
        }

        .warp-one {
            padding: 60px 0;
            .title {
                font-size: 32px;

                font-weight: bold;
                margin: 0 0 60px;
                text-align: center;
            }
            ul {
                li {
                    flex: 1;
                    background: #f9f9f9;
                    border-radius: 16px;
                    align-items: center;
                    padding: 32px;
                    &:nth-child(2) {
                        margin: 0 30px;
                    }
                    img {
                        width: 60px;
                    }
                    .sub_title {
                        font-size: 24px;

                        font-weight: bold;
                        margin: 16px 0 10px;
                    }
                    .sub_tip {
                        font-size: 16px;
                    }
                }
            }
        }
        .warp-two {
            padding: 60px 0;
            .title {
                font-size: 32px;

                font-weight: bold;
            }
            .left {
                flex: 1;
                padding-right: 20px;
                .tip {
                    font-size: 16px;

                    margin: 10px 0 32px;
                }
                .sub_title {
                    font-size: 24px;
                    color: #404040;

                    font-weight: bold;
                }
            }
            .right {
                flex: 1;
                .form {
                    margin-top: 17px;
                    .cooperation_view {
                        padding-bottom: 10px;
                        .cooperation_item {
                            width: 49%;
                        }
                        .width100p {
                            width: 100%;
                        }
                        .ms-flex {
                            display: flex;
                            .width150 {
                                width: 150px;
                            }
                            .width100 {
                                width: 100px;
                            }
                            .ms-flex-1 {
                                flex: 1;
                                margin-left: 10px;
                            }
                        }
                    }
                    .cooperation_view_submit_btn {
                        width: 100%;
                        background: var(--theme-color);
                        border-radius: 4px;
                        font-size: 18px;
                        font-weight: 600;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .form-dialog {
        text-align: center;
        .image {
            width: 96px;
            height: 88px;
        }
        .text1 {
            color: #333333;
            font-size: 28px;
            margin: 30px 0 10px;
        }
        .text2 {
            color: #929292;
            font-size: 20px;
        }
    }
}

@media (max-width: 768px) {
    .about {
        width: 100vw;
        .banner {
            width: 100%;
            img {
                width: 100%;
                height: 115.7005vw;
                display: block;
            }
        }
        .warp-one {
            width: 100vw;
            .content {
                width: 100%;
                .title {
                    font-size: 5.314vw;
                    text-align: center;
                    margin: 13.5266vw auto;

                    font-weight: bold;
                }
                .df {
                    display: flex;
                    flex-direction: column;
                    .df-col {
                        width: 90.3382vw;
                        height: 90.3382vw;
                        padding: 0 4.8309vw;
                        background-color: #f9f9f9;
                        margin: 0 auto 7.7295vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3.8647vw;
                        img {
                            margin-top: 8.9372vw;
                            width: 14.4928vw;
                            height: 14.4928vw;
                        }
                        .sub_title {
                            margin: 5.5556vw 0 1.9324vw;
                            font-size: 4.8309vw;

                            font-weight: bold;
                        }
                        .sub_tip {
                            margin: 0 7.7295vw;
                            font-size: 3.8647vw;
                        }
                    }
                }
            }
        }
        .warp-two {
            width: 100vw;
            margin-top: 6.7633vw;
            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0 4.8309vw;
                .left {
                    margin-top: 6.7633vw;
                    .title {
                        font-size: 5.314vw;

                        font-weight: bold;
                    }
                    .sub_title {
                        font-size: 4.8309vw;

                        font-weight: bold;
                        margin-top: 7.7295vw;
                    }
                    .tip {
                        font-size: 3.8647vw;
                        margin-top: 1.9324vw;
                    }
                    .tip:first-of-type {
                        font-size: 2.1739vw;
                    }
                }
                .right {
                    width: 100%;
                    margin-top: 7.7295vw;
                    overflow: hidden;
                    .title {
                        margin-bottom: 4.1063vw;
                        font-size: 5.314vw;
                        font-weight: 600;
                    }
                    .form {
                        .cooperation_view {
                            width: 100%;
                            margin-top: 3.8647vw;
                            .cooperation_item {
                                width: 100%;
                                margin-bottom: 0 !important;
                                .ms-flex {
                                    display: flex;
                                    flex-direction: column;
                                    .ms-flex-1 {
                                        margin-top: 3.8647vw;
                                    }
                                    .width150 {
                                        width: 100%;
                                    }
                                }
                            }
                            .cooperation_item:last-of-type {
                                margin-top: 3.8647vw;
                            }
                            /deep/ input,
                            /deep/ textarea {
                                border: none;
                                background-color: #f9f9f9;
                            }
                            /deep/ textarea {
                                height: 36.2319vw;
                            }
                        }
                        .df-sb-cen {
                            display: block;
                        }
                        .cooperation_view_submit {
                            margin-bottom: 14.4928vw;
                            .cooperation_view_submit_btn {
                                width: 100%;
                                background: var(--theme-color);
                                border-radius: 4px;
                                font-size: 4.3478vw;
                                font-weight: 600;
                                color: #fff;
                                border: 0;
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
